import { t } from "@lingui/macro"
import axios from "axios"
import type { BigNumber } from "ethers"
import { ethers } from "ethers"
import { useSnackbar } from "notistack"
import React, { useCallback, useEffect, useMemo, useState } from "react"

import ERC20 from "../contracts/Generic/GenericERC20.json"
import Lootbox from "../contracts/Lootbox/Lootbox.json"
import LootboxFactory from "../contracts/Lootbox/LootboxFactory.json"
import LootboxView from "../contracts/Lootbox/LootboxView.json"
import { truncateDecimals } from "../utils/Helpers"
import type { Network } from "../utils/NetworkHelpers"

import { useCountly } from "./CountlyContext"
import { useWeb3Connection } from "./Web3ConnectionContext"

const NULL_ADDRESS = "0x0000000000000000000000000000000000000000"

export const TOKEN_TYPES = {
  0: "ERC20",
  1: "ERC20",
  2: "ERC721",
  3: "ERC1155",
  4: "ERC1155NFT",
} as const

export type TokenType = keyof typeof TOKEN_TYPES

export type LootboxExtraInventory = {
  amountPerUnit: number
  balance: number
  id?: number
}

export type LootboxInventory = {
  tokenAddress: string
  rewardType: TokenType
  amountPerUnit: number
  balance: number
  extra?: LootboxExtraInventory[]
  decimals?: number
}

type LootboxInventoryResult = {
  rewardToken: string
  rewardType: TokenType
  amountPerUnit: BigNumber
  balance: BigNumber
  extra: {
    amountPerUnit: BigNumber
    balance: BigNumber
    id?: BigNumber
  }[]
}

export type LootboxData = {
  address: string
  name?: string
  image?: string
  description?: string
}

export type LootboxContextType = {
  networkData?: Network
  isLoadingLootboxes: boolean
  lootboxes?: LootboxData[]
  deployLootbox: (uri: string) => Promise<string | undefined>
  lootboxToManage?: LootboxData
  lootboxToOpen?: string
  lootboxAllowedTokens?: string[]
  lootboxInventory?: LootboxInventory[]
  lootboxSuppliers?: string[]
  setLootboxToManage: (lootbox: LootboxData | undefined) => void
  addTokenAddressesToLootbox: (tokenAddresses: string[]) => Promise<boolean | undefined>
  updateDepositorAddressesToLootbox: (
    tokenAddressesToAdd: string[],
    depositorAddressesToRemove: string[]
  ) => Promise<boolean | undefined>
  updateRewardsInLootbox: (
    tokens: string[],
    ids: number[],
    amountsPerUnit: BigNumber[]
  ) => Promise<boolean | undefined>
  mintAndTransferToPlayers: (
    playerAddresses: string[],
    lootboxTypes: number[],
    lootboxAmounts: number[]
  ) => Promise<boolean | undefined>
  setLootboxToOpen: (lootboxAddress: string | undefined) => void
}

type LootboxContextProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

const LootboxContext = React.createContext<LootboxContextType | undefined>(undefined)

const LootboxProvider = ({ children }: LootboxContextProviderProps): JSX.Element => {
  const { provider, network, isLoggedIn, networks } = useWeb3Connection()
  const { trackEvent } = useCountly()
  const signer = useMemo(() => provider?.getSigner(0), [provider])

  const networkData = useMemo(
    () =>
      networks.find(
        (newNetwork) =>
          newNetwork.chainId === network?.chainId && !!newNetwork.lootboxFactoryAddress
      ),
    [network, networks]
  )

  const [isLoadingLootboxes, setIsLoadingLootboxes] = useState(false)

  const [lootboxes, setLootboxes] = useState<LootboxData[]>()
  const [lootboxToManage, setLootboxToManage] = useState<LootboxData>()
  const [lootboxAllowedTokens, setLootboxAllowedTokens] = useState<string[]>()
  const [lootboxInventory, setLootboxInventory] = useState<LootboxInventory[]>()
  const [lootboxSuppliers, setLootboxSuppliers] = useState<string[]>()

  const [lootboxToOpen, setLootboxToOpen] = useState<string>()

  const { enqueueSnackbar } = useSnackbar()

  const lootboxFactoryContract = useMemo(() => {
    if (!networkData?.lootboxFactoryAddress || !signer) return
    return new ethers.Contract(networkData.lootboxFactoryAddress, LootboxFactory.abi, signer)
  }, [networkData?.lootboxFactoryAddress, signer])

  // get lootboxes using lootbox factory contract
  const getLootboxes = useCallback(async () => {
    if (!networkData?.lootboxFactoryAddress || !signer || !lootboxFactoryContract) return

    const walletAddress = await signer?.getAddress()

    try {
      setIsLoadingLootboxes(true)
      const lootboxAddresses: string[] = []
      let lootboxAddress: string = ""
      let i = 0

      while (lootboxAddress !== NULL_ADDRESS) {
        lootboxAddress = await lootboxFactoryContract.getLootbox(walletAddress, i)
        if (lootboxAddress === NULL_ADDRESS) break
        lootboxAddresses.push(lootboxAddress)
        i++
      }

      const lootboxesWithMetadata = await Promise.all(
        lootboxAddresses.map(async (newLootboxAddress): Promise<LootboxData> => {
          const lootboxViewContract = new ethers.Contract(
            newLootboxAddress,
            LootboxView.abi,
            signer
          )

          try {
            const uri = await lootboxViewContract.uri("0")
            if (!uri) return { address: newLootboxAddress }

            const { data } = await axios.get(uri)
            return {
              address: newLootboxAddress,
              name: data.name,
              description: data.description,
              image: data.image,
            }
          } catch {
            return { address: newLootboxAddress }
          }
        })
      )

      setIsLoadingLootboxes(false)
      setLootboxes([...lootboxesWithMetadata])
      return lootboxAddresses
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t`Failed to fetch LootBoxes`, { variant: "error", autoHideDuration: 5000 })
    }
  }, [networkData?.lootboxFactoryAddress, lootboxFactoryContract, signer, enqueueSnackbar])

  useEffect(() => {
    getLootboxes()
  }, [getLootboxes])

  // deploy a new LootBox using lootbox factory contract
  const deployLootbox = useCallback(
    async (uri: string) => {
      if (!lootboxFactoryContract || !lootboxes || !isLoggedIn) return
      const newLootboxTx = await lootboxFactoryContract.deployLootbox(uri, lootboxes.length)
      await newLootboxTx.wait()

      const newLootboxes = await getLootboxes()
      if (!newLootboxes?.length) return
      const newLootbox = newLootboxes[newLootboxes.length - 1]

      // track event
      trackEvent({
        key: "lootbox-created",
        segmentation: { lootbox_address: newLootbox },
      })
      return newLootboxes[newLootboxes.length - 1]
    },
    [lootboxFactoryContract, lootboxes, getLootboxes, isLoggedIn, trackEvent]
  )

  // get lootbox contracts instance - View and lootbox contract
  const getLootboxContracts = useCallback(() => {
    if (!lootboxToManage) return
    const lootboxContract = new ethers.Contract(lootboxToManage.address, Lootbox.abi, signer)
    const lootboxViewContract = new ethers.Contract(
      lootboxToManage.address,
      LootboxView.abi,
      signer
    )
    return { lootboxContract, lootboxViewContract }
  }, [lootboxToManage, signer])

  // get lootbox allowed tokens using lootbox contract
  const getLootboxAllowedTokens = useCallback(async () => {
    const lootboxContracts = getLootboxContracts()
    if (!lootboxContracts) {
      setLootboxAllowedTokens(undefined)
      return
    }
    try {
      const { lootboxViewContract } = lootboxContracts
      const allowedTokens = await lootboxViewContract.getAllowedTokens()
      setLootboxAllowedTokens(allowedTokens)
      return allowedTokens
    } catch (err) {
      console.error(err)
      return
    }
  }, [getLootboxContracts])

  const processLootboxInventory = useCallback(
    async (r: LootboxInventoryResult): Promise<LootboxInventory> => {
      let amountPerUnit = 0
      let decimals = 0
      let balance = 0

      if (r.rewardType === 0 || r.rewardType === 1) {
        // process decimals
        const erc20Contract = new ethers.Contract(r.rewardToken, ERC20, signer)
        try {
          decimals = await erc20Contract.decimals()
        } catch (err) {
          console.error("Error fetching decimals")
          console.error(err)
          decimals = 0
        }
        amountPerUnit = parseFloat(
          truncateDecimals(ethers.utils.formatUnits(r.amountPerUnit.toString(), decimals), 2)
        )
        balance = parseFloat(
          truncateDecimals(ethers.utils.formatUnits(r.balance.toString(), decimals), 1)
        )
      } else {
        amountPerUnit = r.amountPerUnit.toNumber()
      }

      return {
        tokenAddress: r.rewardToken,
        rewardType: r.rewardType,
        amountPerUnit,
        balance:
          r.rewardType === 1 || r.rewardType === 0 // ERC20
            ? balance
            : r.rewardType === 2 // ERC721
              ? r.extra?.length || 0
              : r.rewardType === 3 // ERC1155
                ? r.balance.toNumber()
                : r.extra?.length || 0, // ERC1155NFT
        extra: r.extra?.length
          ? r.extra.map((e) => ({
              amountPerUnit: e.amountPerUnit.toNumber(),
              balance: e.balance.toNumber(),
              id: e.id?.toNumber(),
            }))
          : undefined,
        decimals,
      }
    },
    [signer]
  )

  // get lootbox inventory using lootbox contract
  const getLootboxInventory = useCallback(async () => {
    const lootboxContracts = getLootboxContracts()
    if (!lootboxContracts || !provider) {
      setLootboxInventory(undefined)
      return
    }
    try {
      const { lootboxViewContract } = lootboxContracts
      const inventory = (await lootboxViewContract.getInventory()) as {
        result: LootboxInventoryResult[]
        leftoversResult?: LootboxInventoryResult[]
      }

      const mappedInventory: LootboxInventory[] = await Promise.all(
        inventory.result.map(processLootboxInventory)
      )
      const leftoversInventory: LootboxInventory[] = inventory.leftoversResult?.length
        ? (await Promise.all(inventory.leftoversResult.map(processLootboxInventory))).filter(
            (lR) => !mappedInventory.find((mR) => mR.tokenAddress === lR.tokenAddress)
          ) || []
        : []

      setLootboxInventory([...mappedInventory, ...leftoversInventory])
      return [...mappedInventory, ...leftoversInventory]
    } catch (err) {
      console.error(err)
      return
    }
  }, [getLootboxContracts, processLootboxInventory, provider])

  // get lootbox suppliers using lootbox contract
  const getLootboxSuppliers = useCallback(async () => {
    const lootboxContracts = getLootboxContracts()
    if (!lootboxContracts) {
      setLootboxSuppliers(undefined)
      return
    }
    try {
      const { lootboxViewContract } = lootboxContracts
      const suppliers = await lootboxViewContract.getSuppliers()
      setLootboxSuppliers(suppliers)
      return suppliers
    } catch (err) {
      console.error(err)
      return
    }
  }, [getLootboxContracts])

  useEffect(() => {
    if (isLoggedIn) {
      getLootboxAllowedTokens()
      getLootboxInventory()
      getLootboxSuppliers()
    }
  }, [getLootboxInventory, getLootboxSuppliers, getLootboxAllowedTokens, isLoggedIn])

  // add token addresses to lootbox using lootbox contract and lootbox address
  const addTokenAddressesToLootbox = useCallback(
    async (tokenAddresses: string[]) => {
      const lootboxContracts = getLootboxContracts()
      if (!lootboxContracts || !tokenAddresses.length) return
      try {
        const { lootboxContract } = lootboxContracts
        const tokenAddressTx = await lootboxContract.addTokens(tokenAddresses)
        await tokenAddressTx.wait()
        await getLootboxAllowedTokens()

        // track event
        trackEvent({
          key: "lootbox-add-whitelisted-addresses",
          segmentation: { lootbox_address: lootboxToManage?.address },
        })

        enqueueSnackbar("Token addresses have been updated", { variant: "success" })
        return true
      } catch (err) {
        console.error(err)
        enqueueSnackbar("Failed to add token addresses", { variant: "error" })
        return false
      }
    },
    [getLootboxContracts, getLootboxAllowedTokens, enqueueSnackbar, trackEvent, lootboxToManage]
  )

  // add depositor addresses to lootbox using lootbox contract and lootbox address
  const updateDepositorAddressesToLootbox = useCallback(
    async (depositorAddressesToAdd: string[], depositorAddressesToRemove: string[]) => {
      const lootboxContracts = getLootboxContracts()
      if (!lootboxContracts) return
      if (!depositorAddressesToAdd.length && !depositorAddressesToRemove.length) return
      try {
        const { lootboxContract } = lootboxContracts
        if (depositorAddressesToAdd.length) {
          const depositorAddressTx = await lootboxContract.addSuppliers(depositorAddressesToAdd)
          await depositorAddressTx.wait()
        }
        if (depositorAddressesToRemove.length) {
          const depositorAddressTx = await lootboxContract.removeSuppliers(
            depositorAddressesToRemove
          )
          await depositorAddressTx.wait()
        }
        await getLootboxSuppliers()

        // track event
        trackEvent({
          key: "lootbox-add-depositor-addresses",
          segmentation: { lootbox_address: lootboxToManage?.address },
        })

        enqueueSnackbar("Depositor addresses have been updated", { variant: "success" })
        return true
      } catch (err) {
        console.error(err)
        enqueueSnackbar("Failed to add depositor addresses", { variant: "error" })
        return false
      }
    },
    [getLootboxContracts, getLootboxSuppliers, enqueueSnackbar, trackEvent, lootboxToManage]
  )

  // add depositor addresses to lootbox using lootbox contract and lootbox address
  const updateRewardsInLootbox = useCallback(
    async (tokens: string[], ids: number[], amountsPerUnit: BigNumber[]) => {
      const lootboxContracts = getLootboxContracts()
      if (!lootboxContracts) return
      if (tokens.length !== ids.length || ids.length !== amountsPerUnit.length) return
      try {
        const { lootboxContract } = lootboxContracts
        const updateRewardsTx = await lootboxContract.setAmountsPerUnit(tokens, ids, amountsPerUnit)
        await updateRewardsTx.wait()
        await getLootboxInventory()

        // track event
        trackEvent({
          key: "lootbox-update-rewards",
          segmentation: { lootbox_address: lootboxToManage?.address },
        })

        enqueueSnackbar("Lootbox rewards have been updated", { variant: "success" })
        return true
      } catch (err) {
        console.error(err)
        enqueueSnackbar("Failed to add update lootbox rewards", { variant: "error" })
        return false
      }
    },
    [getLootboxContracts, getLootboxInventory, enqueueSnackbar, trackEvent, lootboxToManage]
  )

  // mint lootboxes for players using lootbox contract
  const mintAndTransferToPlayers = useCallback(
    async (playerAddresses: string[], lootboxTypes: number[], lootboxAmounts: number[]) => {
      const lootboxContracts = getLootboxContracts()
      if (!lootboxContracts) return
      if (
        playerAddresses.length !== lootboxTypes.length ||
        lootboxTypes.length !== lootboxAmounts.length
      )
        return
      try {
        const { lootboxContract } = lootboxContracts
        const playerMintsTx = await lootboxContract.mintToMany(
          playerAddresses,
          lootboxTypes,
          lootboxAmounts
        )
        await playerMintsTx.wait()

        // track event
        trackEvent({
          key: "lootbox-transfer-to-players",
          segmentation: { lootbox_address: lootboxToManage?.address },
        })

        enqueueSnackbar("Lootboxes have been minted for players", { variant: "success" })
        return true
      } catch (err) {
        console.error(err)
        enqueueSnackbar("Failed to mint lootboxes for players", { variant: "error" })
        return false
      }
    },
    [getLootboxContracts, enqueueSnackbar, trackEvent, lootboxToManage]
  )

  return (
    <LootboxContext.Provider
      value={{
        networkData,
        isLoadingLootboxes,
        lootboxes,
        deployLootbox,
        lootboxToManage,
        lootboxToOpen,
        lootboxAllowedTokens,
        lootboxInventory,
        lootboxSuppliers,
        setLootboxToManage,
        addTokenAddressesToLootbox,
        updateDepositorAddressesToLootbox,
        updateRewardsInLootbox,
        mintAndTransferToPlayers,
        setLootboxToOpen,
      }}
    >
      {children}
    </LootboxContext.Provider>
  )
}

function useLootbox(): LootboxContextType {
  const context = React.useContext(LootboxContext)
  if (context === undefined) {
    throw new Error("useLootbox must be used within a LootboxContext")
  }
  return context
}

export { LootboxProvider, useLootbox }
