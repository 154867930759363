import { Trans, t } from "@lingui/macro"
import { Box, Button, Typography } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"

import { useLootbox } from "../../../../contexts/LootboxContext"
import { ROUTE_LINKS } from "../../../../routes/routes"

import ManageLootboxHeader from "./ManageHeader"

interface IReviewProps {
  isConfigure?: boolean
  goNext: () => void
}

const Review: React.FC<IReviewProps> = ({ isConfigure }) => {
  const { lootboxAllowedTokens, lootboxSuppliers, lootboxInventory } = useLootbox()

  const navigate = useNavigate()

  return (
    <Box bgcolor="#141414" sx={{ border: "1px solid #262626", borderRadius: "5px" }}>
      <ManageLootboxHeader
        step={isConfigure ? t`Step 6` : undefined}
        heading={t`Review summary`}
        subtitle={t`Review your inputs before finalizing your LootBox configurations. You can also change these settings later in the Overview page.`}
      />
      <Box
        sx={{
          borderTop: "1px solid #262626",
          borderBottom: "1px solid #262626",
        }}
        p={4}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        {/* token */}
        <Box mb={2}>
          <Typography pb={0.5}>
            <Trans>Token addresses</Trans>
          </Typography>
          {lootboxAllowedTokens?.length ? (
            lootboxAllowedTokens?.map((l, i) => (
              <Box display="flex">
                <Typography color="#BFBFBF" mr={1}>
                  Address {i + 1}:
                </Typography>
                <Typography color="#B4FF68">{l}</Typography>
              </Box>
            ))
          ) : (
            <Typography color="#BFBFBF">No token addresses</Typography>
          )}
        </Box>

        {/* depositor */}
        <Box mb={2}>
          <Typography pb={0.5}>
            <Trans>Review depositor addresses</Trans>
          </Typography>
          {lootboxSuppliers?.length ? (
            lootboxSuppliers?.map((l, i) => (
              <Box display="flex">
                <Typography color="#BFBFBF" mr={1}>
                  Address {i + 1}:
                </Typography>
                <Typography color="#B4FF68">{l}</Typography>
              </Box>
            ))
          ) : (
            <Typography color="#BFBFBF">No depositor addresses</Typography>
          )}
        </Box>

        {/* rewards  */}
        <Box mb={2}>
          <Typography pb={0.5}>
            <Trans>Confirm reward type and units</Trans>
          </Typography>
          <Typography mr={1}>{lootboxInventory?.length || "no"} rewards</Typography>
        </Box>
      </Box>
      {isConfigure && (
        <Box p={4} display="flex" justifyContent="space-between">
          <div />
          <Button onClick={() => navigate(ROUTE_LINKS.Lootboxes)} variant="contained">
            <Trans>Finish</Trans>
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default Review
