import { Trans, t } from "@lingui/macro"
import { Box, Typography } from "@mui/material"
import React from "react"

import { useLootbox } from "../../../../contexts/LootboxContext"
import { SoftButton } from "../../../elements/SoftButton"

import ManageLootboxHeader from "./ManageHeader"

interface ISendLootProps {
  isConfigure?: boolean
  goNext: () => void
}

const SendLoot: React.FC<ISendLootProps> = ({ goNext, isConfigure }) => {
  const { lootboxToManage } = useLootbox()

  return (
    <Box bgcolor="#141414" sx={{ border: "1px solid #262626", borderRadius: "5px" }}>
      <ManageLootboxHeader
        step={isConfigure ? t`Step 3` : undefined}
        heading={t`Send Rewards`}
        subtitle={t`Using the method of your choice, transfer your reward NFT’s to your new LootBox contract address specified below`}
      />
      <Box
        sx={{
          borderTop: "1px solid #262626",
          borderBottom: "1px solid #262626",
        }}
        p={4}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Box
          border="1px solid #8C8C8C"
          borderRadius="10px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={1}
        >
          <Typography color="#DFF7C7">{lootboxToManage?.address || ""}</Typography>
        </Box>
      </Box>
      <Box p={4} display="flex" justifyContent="space-between">
        <div />
        {isConfigure ? (
          <SoftButton onClick={goNext}>
            <Trans>Continue</Trans>
          </SoftButton>
        ) : null}
      </Box>
    </Box>
  )
}

export default SendLoot
