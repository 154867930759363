import { Trans, t } from "@lingui/macro"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { Box, TextField, Typography } from "@mui/material"
import { BigNumber, ethers } from "ethers"
import { useSnackbar } from "notistack"
import React, { useCallback, useEffect, useState } from "react"

import { TOKEN_TYPES, useLootbox } from "../../../../contexts/LootboxContext"
import type { LootboxInventory } from "../../../../contexts/LootboxContext"
import { roundToDecimalPlaces } from "../../../../utils/Helpers"
import { RoundIconButton } from "../../../elements/RoundIconButton"
import { SoftButton } from "../../../elements/SoftButton"

import ManageLootboxHeader from "./ManageHeader"

interface IRewardProps {
  isConfigure?: boolean
  goNext: () => void
}

interface ILootboxReward {
  tokenAddress: string
  rewardType: keyof typeof TOKEN_TYPES
  amountPerUnit: string
  remainingBalance: number
  tokenId?: number
  decimals?: number
}

const getLootboxRewardsFromInventory = (
  lootboxInventory?: LootboxInventory[]
): ILootboxReward[] => {
  if (!lootboxInventory) return []
  const newLootboxRewards: ILootboxReward[] = []
  for (const lootboxInventoryItem of lootboxInventory) {
    if (lootboxInventoryItem.rewardType === 3 && lootboxInventoryItem.extra?.length) {
      // 1155 read extra token ids
      for (const itemExtra of lootboxInventoryItem.extra) {
        if (itemExtra.id !== undefined) {
          newLootboxRewards.push({
            tokenAddress: lootboxInventoryItem.tokenAddress,
            amountPerUnit: itemExtra.amountPerUnit.toString(),
            remainingBalance: itemExtra.balance,
            rewardType: lootboxInventoryItem.rewardType,
            tokenId: itemExtra.id,
            decimals: lootboxInventoryItem.decimals,
          })
        }
      }
      // 721, 20, 1155NFT do not read tokenId
    } else {
      newLootboxRewards.push({
        tokenAddress: lootboxInventoryItem.tokenAddress,
        amountPerUnit: lootboxInventoryItem.amountPerUnit.toString(),
        remainingBalance: lootboxInventoryItem.balance,
        rewardType: lootboxInventoryItem.rewardType,
        decimals: lootboxInventoryItem.decimals,
      })
    }
  }
  return newLootboxRewards
}

const RewardUnits: React.FC<IRewardProps> = ({ goNext, isConfigure }) => {
  const { lootboxInventory, updateRewardsInLootbox } = useLootbox()

  const [lootboxRewards, setLootboxRewards] = useState<ILootboxReward[]>(
    getLootboxRewardsFromInventory(lootboxInventory)
  )
  const [isRewardsLoading, setIsRewardsLoading] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (lootboxInventory) {
      setHasChanged(false)
      setLootboxRewards(getLootboxRewardsFromInventory(lootboxInventory))
    }
  }, [lootboxInventory])

  const handleRewardsSubmit = useCallback(async () => {
    if (!hasChanged) return

    // validate amounts per unit
    if (
      lootboxRewards.find((l) =>
        l.rewardType === 0 || l.rewardType === 1
          ? Number.isNaN(parseFloat(l.amountPerUnit)) || parseFloat(l.amountPerUnit) < 0
          : Number.isNaN(parseInt(l.amountPerUnit)) || parseInt(l.amountPerUnit) < 0
      )
    ) {
      enqueueSnackbar("Number of rewards must be valid", {
        variant: "error",
        autoHideDuration: 5000,
      })
      return
    }

    // validate amounts per unit must be 1 or 0 for 721 and 1155NFT
    if (
      lootboxRewards.find(
        (l) => (l.rewardType === 2 || l.rewardType === 4) && parseInt(l.amountPerUnit) > 1
      )
    ) {
      enqueueSnackbar("Number of rewards for ERC721 and ERC1155NFT cannot be more than 1", {
        variant: "error",
        autoHideDuration: 5000,
      })
      return
    }

    const amountsPerUnit = lootboxRewards.map((r) =>
      r.rewardType === 0 || r.rewardType === 1
        ? ethers.utils.parseUnits(r.amountPerUnit, r.decimals || 1)
        : BigNumber.from(parseInt(r.amountPerUnit))
    )

    setIsRewardsLoading(true)

    // handle token address updates
    const wasSuccess = await updateRewardsInLootbox(
      lootboxRewards.map((r) => r.tokenAddress),
      lootboxRewards.map((r) => (r.tokenId ? r.tokenId : 0)),
      amountsPerUnit
    )

    setIsRewardsLoading(false)

    // go to next page
    if (wasSuccess && isConfigure) {
      goNext()
    }
  }, [hasChanged, updateRewardsInLootbox, goNext, isConfigure, lootboxRewards, enqueueSnackbar])

  return (
    <Box bgcolor="#141414" sx={{ border: "1px solid #262626", borderRadius: "5px" }}>
      <ManageLootboxHeader
        step={isConfigure ? t`Step 4` : undefined}
        heading={t`Manage Reward Units`}
        subtitle={t`Specify how much of each token is distributed per reward item (default 1).`}
      />
      <Box
        sx={{
          borderTop: "1px solid #262626",
          borderBottom: "1px solid #262626",
        }}
        p={4}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Box display="flex" width="100%" alignItems="center">
          <Typography width="45%">
            <Trans>Loot token contracts</Trans>
          </Typography>
          <Typography width="15%" align="center">
            <Trans>Reward type</Trans>
          </Typography>
          <Typography width="25%" align="center" minWidth={200}>
            <Trans># of rewards</Trans>
          </Typography>
          <Typography width="15%" align="center">
            <Trans>Remaining balance</Trans>
          </Typography>
        </Box>
        {lootboxRewards?.length ? (
          lootboxRewards.map((lootboxReward, i) => (
            <Box display="flex" width="100%" mt={2} mb={2} alignItems="center" key={i}>
              <Box width="45%" pr={2}>
                <Typography
                  sx={{
                    maxWidth: "400px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {lootboxReward.rewardType === 3 && lootboxReward.tokenId !== undefined
                    ? `${lootboxReward.tokenId} - `
                    : ""}
                  {lootboxReward.tokenAddress}
                </Typography>
              </Box>
              <TextField
                size="small"
                disabled={true}
                value={TOKEN_TYPES[lootboxReward.rewardType]}
                sx={{ width: "15%", pr: 2, pl: 2 }}
              />
              <Box
                display="flex"
                gap={1}
                alignItems="center"
                justifyContent="center"
                width="25%"
                pl={3.5}
                pr={3.5}
                minWidth={200}
              >
                <RoundIconButton
                  variant="contained"
                  size="small"
                  sx={{ fontSize: "24px" }}
                  onClick={() => {
                    const amount = parseFloat(lootboxRewards[i].amountPerUnit)
                    if (Number.isNaN(amount) || amount < 1) return
                    lootboxRewards[i].amountPerUnit = roundToDecimalPlaces(amount - 1, 2).toString()
                    setLootboxRewards([...lootboxRewards])
                    setHasChanged(true)
                  }}
                >
                  <RemoveIcon />
                </RoundIconButton>
                <TextField
                  value={lootboxReward.amountPerUnit}
                  size="small"
                  sx={{ maxWidth: "80px", alignSelf: "center" }}
                  onChange={(e) => {
                    lootboxRewards[i].amountPerUnit = e.target.value
                    setLootboxRewards([...lootboxRewards])
                    setHasChanged(true)
                  }}
                />
                <RoundIconButton
                  variant="contained"
                  size="small"
                  onClick={() => {
                    const amount = parseFloat(lootboxRewards[i].amountPerUnit)
                    if (Number.isNaN(amount)) return
                    lootboxRewards[i].amountPerUnit = (amount + 1).toString()
                    setLootboxRewards([...lootboxRewards])
                    setHasChanged(true)
                  }}
                >
                  <AddIcon />
                </RoundIconButton>
              </Box>
              <Typography width="15%" align="center">
                {lootboxReward.remainingBalance}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography sx={{ mt: 4, mb: 4, color: "#BFBFBF" }}>
            <Trans>No reward units added</Trans>
          </Typography>
        )}
      </Box>
      <Box p={4} display="flex" justifyContent="space-between">
        <div />
        <SoftButton onClick={handleRewardsSubmit} loading={isRewardsLoading}>
          <Trans>{isConfigure ? "Continue" : "Save changes"}</Trans>
        </SoftButton>
      </Box>
    </Box>
  )
}

export default RewardUnits
