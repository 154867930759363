import { Trans, t } from "@lingui/macro"
import { Box, TextField, Typography } from "@mui/material"
import { ethers } from "ethers"
import React, { useEffect, useState } from "react"

import { CrossCircleIcon } from "../../../../assets/icons/CrossCircleIcon"
import { useLootbox } from "../../../../contexts/LootboxContext"
import { SoftButton } from "../../../elements/SoftButton"

import ManageLootboxHeader from "./ManageHeader"

interface ILootboxDepositorProps {
  isConfigure?: boolean
  goNext: () => void
}

const DepositorAddresses: React.FC<ILootboxDepositorProps> = ({ goNext, isConfigure }) => {
  const { updateDepositorAddressesToLootbox, lootboxSuppliers } = useLootbox()

  const [depositorAddresses, setDepositorAddresses] = useState<string[]>(
    lootboxSuppliers?.length ? [] : [""]
  )
  const [depositorAddressErrors, setDepositorAddressErrors] = useState<string[]>([])
  const [isDepositorAddressesLoading, setIsDepositorAddressesLoading] = useState(false)

  useEffect(() => {
    if (!lootboxSuppliers?.length) setDepositorAddresses([""])
    else setDepositorAddresses(lootboxSuppliers)
  }, [lootboxSuppliers])

  const handleAddDepositorAddressesSubmit = async (): Promise<void> => {
    setDepositorAddressErrors([])

    // find added addresses
    const depositorAddressesToAdd = depositorAddresses.filter(
      (address) => !lootboxSuppliers?.includes(address)
    )

    // find removed addresses
    const depositorAddressesToRemove = lootboxSuppliers
      ? lootboxSuppliers.filter((address) => !depositorAddresses?.includes(address))
      : []

    if (!depositorAddressesToAdd.length && !depositorAddressesToRemove.length) return

    // validate depositor addresses
    const newDepositorAddressErrors = depositorAddresses.map((d) =>
      ethers.utils.isAddress(d) ? "" : "Depositor address is not valid"
    )
    setDepositorAddressErrors(newDepositorAddressErrors)
    const areDepositorAddressesValid = !newDepositorAddressErrors.find((dE) => !!dE)
    if (!areDepositorAddressesValid) return

    setIsDepositorAddressesLoading(true)

    // handle token address updates
    const wasSuccess = await updateDepositorAddressesToLootbox(
      depositorAddressesToAdd,
      depositorAddressesToRemove
    )

    setIsDepositorAddressesLoading(false)

    // go to next page
    if (wasSuccess && isConfigure) {
      goNext()
    }
  }

  return (
    <Box bgcolor="#141414" sx={{ border: "1px solid #262626", borderRadius: "5px" }}>
      <ManageLootboxHeader
        step={isConfigure ? t`Step 2` : undefined}
        heading={t`Designate Depositor Access`}
        subtitle={t`Next, designate wallet addresses to transfer your whitelisted token rewards into the LootBox. This step prevents malicious behaviour by helping your LootBox contract identify which senders are safe to accept rewards from.`}
      />
      <Box
        sx={{
          borderTop: "1px solid #262626",
          borderBottom: "1px solid #262626",
        }}
        p={4}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        {depositorAddresses.map((depositorAddress, i) => (
          <Box display="flex" flex={1} width="100%" alignItems="flex-start" mt={1} mb={1} key={i}>
            <TextField
              size="small"
              label={t`Token address ${i + 1}`}
              value={depositorAddress}
              onChange={(e) => {
                setDepositorAddresses((prevState) => [
                  ...prevState.map((address, index) => (index === i ? e.target.value : address)),
                ])
              }}
              placeholder="0x..."
              error={!!depositorAddressErrors[i]}
              helperText={depositorAddressErrors[i]}
              fullWidth
            />
            <Box
              pt={1.5}
              pl={1.5}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setDepositorAddresses(depositorAddresses.filter((d, index) => i !== index))
                setDepositorAddressErrors([])
              }}
            >
              <CrossCircleIcon />
            </Box>
          </Box>
        ))}
        <Typography
          onClick={() => {
            setDepositorAddresses([...depositorAddresses, ""])
            setDepositorAddressErrors([])
          }}
          sx={{
            p: 0,
            mt: 2,
            pl: 1,
            color: "#BFBFBF",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          <Trans>Add another address</Trans>
        </Typography>
      </Box>
      <Box p={4} display="flex" justifyContent="space-between">
        <div />
        <SoftButton
          onClick={handleAddDepositorAddressesSubmit}
          loading={isDepositorAddressesLoading}
        >
          <Trans>{isConfigure ? "Continue" : "Save changes"}</Trans>
        </SoftButton>
      </Box>
    </Box>
  )
}

export default DepositorAddresses
